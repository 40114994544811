.orders .content {
	display: flex;
	flex-direction: column;
	background-color: #eee !important;
}

.orders-header {
	background-color: var(--c1);
	color: var(--c1c);
	padding-top: 30px;
	padding-bottom: 45px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	position: relative;
}

.orders-header .close-button {
	color: var(--c1c);
}

.orders-scroll {
	flex-grow: 1;
	overflow: auto;
	transform: translate(0, -30px);
	margin-left: 15px;
	margin-right: 15px;
}

.orders-content {
	background-color: #fff;
	padding: 15px;
	display: grid;
	gap: 10px;
	border-bottom: 1px solid #eee;
}

.orders-subtitle {
	font-weight: bold;
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.orders-subtitle button {
	border: 0;
	padding: 5px 10px;
	font-size: 14px;
	background-color: var(--c2);
	color: var(--c2c);
}

.orders-total {
	font-size: 22px;
	margin-bottom: 10px;
	margin-right: 15px;
	margin-left: 15px;
	text-align: right;
}

.orders-total span {
	font-weight: bold;
}

.orders .orders-footer .buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: space-between;
}

.orders .orders-footer .buttons button {
	border: 0;
	padding: 10px;
	font-weight: bold;
	font-size: 14px;
	background-color: #fff;
}

.orders .orders-footer .buttons button.button-primary {
	background-color: var(--c2);
	color: var(--c2c);
}

.orders .orders-products {
	display: grid;
	gap: 10px;
}

.orders .orders-products > * {
	display: flex;
	gap: 10px;
	align-items: center;
}
