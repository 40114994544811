.category {
	color: #000000;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	padding: 15px;
	height: 100%;
	border: 0;
	background-color: transparent;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.category:hover {
	color: #000000;
	background-color: #eee;
}

.category .img {
	margin-bottom: 10px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-grow: 1;
	max-height: 200px;
}

.category .img img {
	max-width: 100%;
	max-height: 100%;
}

.category p {
	text-align: center;
	margin-bottom: 0;
	font-weight: bold;
}
