.temp-user-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100dvh;
	z-index: 160;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000095;
}

.temp-user-popup .content {
	background-color: #fff;
	padding: 15px;
	border-radius: 15px;
	width: 100%;
	max-width: 400px;
}

.temp-user-popup .button {
	border: 0;
	display: block;
	padding: 10px;
	font-weight: bold;
	width: 100%;
	color: #000;
}
