.qty-selector {
	display: flex;
	align-items: center;
}

.qty-selector button {
	border: 0;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	background-color: transparent;
}

.qty-selector button div {
	padding: 0px 9px;
	border-radius: 100%;
	font-size: 22px;
	color: var(--c1c);
}

.qty-selector button.minus div {
	background-color: var(--c1o);
}

.qty-selector button.minus.enabled div {
	background-color: var(--c1);
}

.qty-selector button.plus div {
	background-color: var(--c1o);
}

.qty-selector button.plus.enabled div {
	background-color: var(--c1);
}

.qty-selector .qty {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	flex-shrink: 0;
}

.qty-selector .qty-label {
	margin-left: 10px;
}

/* KIOSK */
