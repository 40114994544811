@media (width >= 768px) {
  #variants .content {
    height: auto;
    max-height: 100%;
  }
}

#variants .title {
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  margin-right: 25px;
}

#variants .subtitle {
  font-weight: bold;
  padding: 5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
}

#variants .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

#variants .button {
  border: 0;
  display: block;
  flex-grow: 1;
  padding: 10px;
  font-weight: bold;
}

#variants .variants-category {
  margin-left: -5px;
  margin-right: -5px;
  padding: 0 5px;
  padding: 15px;
}
#variants .variants-category.alert {
  background-color: #ffeeee;
  padding: 15px;
  border: 1px solid #c8161d;
  margin-bottom: 0px;
  border-radius: 0px;
}
#variants .variants-category.alert .variants-category-title {
  font-size: 24px;
}

#variants .variants-category-title {
  font-weight: bold;
}

.finalPrice {
  text-align: center;
  padding: 5px;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

#variants .variants.multi {
  display: flex;
  flex-wrap: wrap;
}

#variants .variants .variant {
  position: relative;
  display: flex;
  align-items: center;
}

#variants .variants .variant .variant-title {
  font-size: var(--normal);
}

#variants .variants .variant .variant-title .price {
  font-size: var(--small);
}

#variants .ingredient {
  position: relative;
  display: flex;
  align-items: center;
}

#variants .variants.multi .variant {
  width: 100%;
}

@media (min-width: 768px) {
  #variants .variants.multi .variant {
    width: 33%;
  }
}

#variants input[type="checkbox"] {
  opacity: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#variants input[type="radio"] {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#variants .variants-category button.radio {
  padding: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 0;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

#variants .variants-category button.checked {
  background-color: #c8161d;
  content: "x";
}

#variants .variants-category button.checked::after {
  display: block;
  content: "";
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: -2px;
}

#variants .checkbox_checkmark {
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  pointer-events: none;
  border-radius: 50%;
}

#variants input:checked ~ .checkbox_checkmark {
  background-color: #c8161d;
}

#variants input:checked ~ .checkbox_checkmark::after {
  display: block;
  content: "";
}

#variants .checkbox_checkmark::after {
  position: absolute;
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#variants .allergens {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#variants .subproduct {
  display: flex;
  align-items: center;
  gap: 10px;
}

#variants .subproduct img {
  width: 50px;
}

/* KIOSK */

#root.kiosk #variants .buttons {
  font-size: 30px;
}
