.popup.legal-popup {
	z-index: 200;
}

.popup.legal-popup .content {
	display: flex;
	flex-direction: column;
	max-width: 800px;
	max-height: 100%;
}

@media (width >= 768px) {
	.popup.legal-popup .content {
		height: auto;
		max-height: 100%;
	}
}

.popup.legal-popup .content .srollable-content {
	flex-grow: 1;
	overflow: auto;
	margin-bottom: 15px;
	text-align: justify;
}

.popup.legal-popup .button {
	border: 0;
	display: block;
	padding: 10px;
	font-weight: bold;
	width: 100%;
}
