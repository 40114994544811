.slider-container {
	font-size: 0;
}

.slide {
	display: block;
	background-size: cover;
	background-position: center;
	height: 100px;
}
