#loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100dvh;
	background-color: #00000090;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 300;
}

#loading .content {
	background-color: #fff;
	border-radius: 10px;
	padding: 15px 20px;
	font-size: 30px;
}

/* KIOSK */
#root.kiosk #loading .content {
	font-size: 50px;
	padding: 30px;
}
