.limits {
	position: fixed;
	display: flex;
	align-items: center;
	top: 0;
	right: -175px;
	height: 100%;
	height: 100dvh;
	pointer-events: none;
	transition: all 0.3s;
	width: 200px;
}

.limits.open {
	right: 0px;
}

.limits .limits-container {
	pointer-events: auto;
	display: flex;
	align-items: flex-start;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	flex-grow: 1;
}

.limits button {
	background-color: var(--c2);
	color: var(--c2c);
	border: 0;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	font-size: 25px;
	width: 30px;
	min-height: 100px;
}

.limits .content {
	font-size: 12px;
	line-height: 16px;
	background-color: var(--c2);
	color: var(--c2c);

	flex-grow: 1;
	padding: 10px;
	max-width: 200px;
}
.limits .container-coves {
	border-bottom: 1px solid var(--c2c);
	display: flex;
	gap: 20px;
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.limits .covers {
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
}

.limits .list-group {
	display: grid;
	gap: 10px;
	max-height: 150px;
	min-height: 100px;
	overflow: scroll;
}
