.header {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 1fr;
	position: relative;
	background-color: var(--ch);
	color: var(--cho);
	padding: 10px;
}

.header .header-left {
	display: flex;
	align-items: center;
}

.header .header-right {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.header .logo {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: 0;
	width: auto;
}

.header .logo img {
	max-width: 100%;
	max-height: 100%;
}

#menu_button {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	cursor: pointer;
	color: var(--cho);
}

#menu_button svg {
	font-size: 30px;
}

.header .back {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	text-decoration: none;
	position: relative;
	border: 0;
	background-color: transparent;
	color: var(--cho);
	padding: 0px;
}

.header .back svg {
	font-size: 30px;
}

.header .promo-button {
	background-color: var(--c2c);
	color: var(--c2);
	border: 0;
	margin: 0;
	padding: 5px 10px;
	font-weight: bold;
	border-radius: 5px;
	font-size: 11px;
	text-transform: uppercase;
}

.header .promo-button svg {
	margin-right: 5px;
}

/* KIOSK */

#root.kiosk .header .logo {
	height: 100px !important;
}
#root.kiosk .header #menu_button {
	width: 100px;
	height: 100px;
}
#root.kiosk .header #menu_button i {
	font-size: 70px;
}
#root.kiosk .header {
	padding: 20px !important;
}
#root.kiosk .header .promo-button {
	font-size: 30px;
	border-radius: 10px;
	padding: 10px 20px;
}
