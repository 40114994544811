#alert {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100dvh;
  background-color: #00000090;
  padding: 15px;
  z-index: 200;
}

#alert .content {
  background-color: #fff;
  padding: 15px;
  width: 100%;
  max-width: 500px;
}

#alert .title {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 15px;
}

#alert .message {
  margin-bottom: 15px;
  text-align: center;
}

#alert .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
}

#alert .button {
  border: 0;
  display: block;
  flex-grow: 1;
  margin: 0 10px;
  padding: 10px;
  font-weight: bold;
}

#alert .button.success {
  background-color: rgb(34, 168, 0);
  color: #fff;
}

/* KIOSK */
#root.kiosk #alert .content {
  max-width: 800px;
}
#root.kiosk #alert .title {
  font-size: 60px;
}
#root.kiosk #alert .message {
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 40px;
}
#root.kiosk #alert .buttons {
  font-size: 40px;
}
