#pin {
  width: 100%;
  height: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

#pin .logo {
  flex-grow: 1;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pin .logo img {
  max-width: 150px;
  max-height: 150px;
}

#pin .form {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pin .content {
  width: 100%;
  padding: 50px 0;
}

#pin h2 {
  text-align: center;
}

#pin p {
  text-align: center;
  margin-bottom: 50px;
}

#pin .form-line {
  display: flex;
  margin-bottom: 30px;
}

#pin .form-line .label {
  text-align: right;
  width: 50%;
  padding: 10px;
  font-weight: bold;
}

#pin .form-line input {
  flex-grow: 1;
  border: 0;
  margin: 0 15px;
}

#pin .form-line select {
  flex-grow: 1;
  border: 0;
  margin: 0 15px;
}

#pin button {
  padding: 10px;
  border: 0;
  width: 80%;
  margin: 0 auto;
  display: block;
  font-weight: bold;
}

#pin .lang-selector-container {
  width: fit-content;
  margin: 0 auto;
  margin-top: 60px;
}

#pin .lang-selector-container hr {
  display: none;
}

#root.kiosk #pin h2 {
  font-size: 70px;
  margin-bottom: 30px;
}
#root.kiosk #pin button {
  font-size: 40px;
  width: fit-content;
  padding: 20px 30px;
}
#root.kiosk #pin .logo {
  max-height: 500px;
}
#root.kiosk #pin .logo img {
  max-height: 400px;
  max-width: 400px;
}
