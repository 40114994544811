#user {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100dvh;
	background-color: #00000090;
	z-index: 200;
	color: #000;
	padding: 15px;
}

#user .content {
	background-color: #fff;
	padding: 15px;
	width: 100%;
	max-width: 500px;
	max-height: 100%;
	overflow: auto;
	position: relative;
	display: flex;
	flex-direction: column;
}

#user.full {
	background-color: var(--ch);
	padding: 0px;
	align-items: center;
}

#user.full .content {
	background-color: var(--ch);
}

#user.full form {
	background-color: #fff;
	padding: 15px;
	border-radius: 10px;
}

#user .logo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	height: 200px;
}

#user .logo img {
	max-width: 200px;
}

#user .title {
	text-align: center;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 15px;
}

#user .message {
	margin-bottom: 15px;
}

#user .button {
	border: 0;
	display: block;
	padding: 10px;
	font-weight: bold;
	width: 100%;
	color: #000;
}

#user .buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 -10px;
}

#user .buttons .button {
	flex-grow: 1;
	margin: 0 10px;
}

#user .form-label {
	font-weight: 600;
}

#user input:not([type="checkbox"]) {
	border-top: 0px;
	border-right: 0px;
	border-left: 0px;
	border-radius: 0px;
}

#user .dashboard {
	margin-bottom: 10px;
}

#user .dashboard button {
	background-color: black;
	border: 0;
	color: #fff;
	margin-right: 5px;
	padding: 5px 7px;
	margin-bottom: 5px;
	font-size: 14px;
	border-radius: 5px;
}
#user .dashboard button svg {
	font-size: 20px;
	width: 18px;
	height: 18px;
}

#user .qr {
	max-width: 300px;
	max-height: 300px;
	align-self: center;
}

.mobile-container {
	display: flex;
	gap: 10px;
}

.mobile-container input,
.mobile-container select {
	display: block;
	border: 0;
	border-bottom: 1px solid #ced4da;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
}

.mobile-container input {
	flex-grow: 1;
}
