.payment-popup {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	height: 100dvh;
	width: 100%;
	background-color: #00000090;
	padding: 15px;
	z-index: 150;
	display: flex;
	align-items: center;
	justify-content: center;
}

.payment-popup .content {
	padding: 15px;
	background-color: #fff;
	border-radius: 10px;
	width: 100%;
	max-width: 400px;
	color: #000;
	overflow: auto;
	max-height: 100%;
}

.payment-popup .title {
	text-align: center;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 15px;
}

.payment-popup button {
	border: 0;
	display: block;
	padding: 10px;
	font-weight: bold;
	width: 100%;
	margin-bottom: 15px;
}

.payment-popup button.primary {
	background-color: var(--c1);
	color: var(--c1c);
}

.payment-popup button:last-child {
	margin-bottom: 0;
}

.payment-popup .paypal {
	margin-bottom: 10px;
}

.payment-popup .disclaimer {
	font-size: 12px;
	color: #000;
	text-align: center;
	margin-bottom: 15px;
}

.payment-popup .disclaimer img {
	width: 120px;
}
