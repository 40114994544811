@media (width >= 768px) {
  #product-sheet .content {
    height: auto;
    max-height: 100%;
  }
}

#product-sheet .title {
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  margin-right: 25px;
}

#product-sheet .description {
  font-size: var(--normal);
}

#product-sheet .img {
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  #product-sheet .img {
    max-height: 150px;
  }
}

#product-sheet .img img {
  max-width: 100%;
  max-height: 100%;
}

#product-sheet .subtitle {
  font-weight: bold;
  padding: 5px;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
  font-size: var(--medium);
}

#product-sheet .ingredients {
  font-size: var(--normal);
}

#product-sheet .allergens {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#product-sheet .allergens > div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--small);
}
#product-sheet .allergens > div svg {
  width: -webkit-fit-content;
}

#product-sheet .allergens .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  margin: 0;
}

#product-sheet .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

#product-sheet .button {
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: var(--c1);
  color: var(--c1c);
  padding: 5px 10px;
}

#product-sheet .button i {
  padding: 10px;
  border-radius: 100%;
  font-size: 18px;
}
