.lang-selector {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}

.lang-selector .label {
	font-size: 14px;
}

.lang-selector .lang-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;
}

.lang-selector .lang-buttons a {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	width: 40px;
}

.lang-selector .lang-buttons a img {
	width: 40px;
}
