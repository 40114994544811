@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
	--c1: #494373;
	--c1c: #fff;
	--c2: #d50000;
	--c2c: #fff;
	--extrasmall: 9px;
	--small: 12px;
	--normal: 14px;
	--medium: 16px;
}

@media (width >= 768px) {
	:root {
		--extrasmall: 12px;
		--small: 14px;
		--normal: 16px;
		--medium: 18px;
	}
}

@media (width >= 1400px) {
	:root {
		--extrasmall: 14px;
		--small: 16px;
		--normal: 18px;
		--medium: 20px;
	}
}

html {
	height: 100%;
	height: 100dvh;
	height: -webkit-fill-available;
}

body {
	height: 100%;
	height: 100dvh;
	font-family: "Poppins", sans-serif;
}

button,
a {
	color: inherit;
}

#root {
	height: 100%;
	height: 100dvh;
}
.spin-loading {
	-webkit-animation: fa-spin 2s linear infinite;
	animation: fa-spin 2s linear infinite;
}
.page {
	width: 100%;
	height: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
}

.page .columns {
	flex-grow: 1;
	display: flex;
	overflow: hidden;
	height: 100%;
}

.page .sidebar {
	width: 30%;
	min-width: 100px;
	max-width: 250px;
	background-color: #eee;
	overflow: auto;
}

.page .sidebar .course-title {
	font-weight: bold;
	font-size: 12px;
	text-align: center;
	padding: 10px;
	background-color: var(--c2);
	color: var(--c2c);
	text-transform: uppercase;
}

.page .sidebar button {
	display: block;
	width: 100%;
	background-color: #eee;
	color: #000;
	margin: 0;
	padding: 10px 20px;
	border: 0;
	text-align: left;
	font-size: 12px;
}
.page .sidebar .category-tags {
	display: flex;
	justify-content: left;
}
.page .sidebar .category-tags > div {
	color: var(--c2c);
	background-color: var(--c2);
	font-size: 10px;
	padding: 2px 5px;
	border-radius: 3px;
	width: fit-content;
	margin-bottom: 5px;
}

@media (width >= 768px) {
	.page .sidebar .course-title,
	.page .sidebar button {
		font-size: 14px;
	}
}

@media (width >= 1400px) {
	.page .sidebar .course-title,
	.page .sidebar button {
		font-size: 16px;
	}
}

.page .sidebar button.selected {
	background-color: #fff;
	color: #000;
	font-weight: bold;
	position: relative;
}

.page .sidebar button.selected::before {
	content: "";
	display: block;
	background-color: var(--c2);
	width: 5px;
	height: calc(100% - 4px);
	position: absolute;
	top: 2px;
	left: 2px;
}

.page .main {
	flex-grow: 1;
	position: relative;
	overflow: auto;
}

.page .main .inner-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #fff;
}

.page .main .inner-content {
	overflow: auto;
	padding: 15px;
}

.popup {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	height: 100dvh;
	z-index: 100;
	background-color: #00000050;
	overflow: hidden;
}

@media (width >= 768px) {
	.popup {
		padding: 30px;
	}
}

.popup .content {
	width: 100%;
	height: 100%;
	max-width: 1200px;
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #eee !important;
}

@media (width >= 768px) {
	.popup {
		padding: 30px;
	}
}

.popup .popup-header {
	background-color: var(--c1);
	color: var(--c1c);
	padding-top: 30px;
	padding-bottom: 45px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	position: relative;
}

.popup .popup-header .close-button {
	color: var(--c1c);
}

.popup .popup-scroll {
	flex-grow: 1;
	overflow: auto;
	margin-left: 15px;
	margin-right: 15px;
	margin-top: -30px;
	z-index: 1;
}

.popup .popup-content {
	background-color: #fff;
	margin-bottom: 15px;
	padding: 15px;
	min-height: 60px;
}

.confirm {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 15px;
	width: 100%;
	height: 100%;
	height: 100dvh;
	z-index: 100;
	background-color: #ffffffec;
}
.confirm {
	font-size: 48px;
	font-weight: 600;
	text-align: center;
}
.confirm div {
	font-size: 22px;
}

.close-button {
	background-color: transparent;
	color: #000000;
	border: 0;
	padding: 0;
	outline: 0;
	font-size: 30px;
	font-weight: bold;
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
}

.bold {
	font-weight: bold;
}

.small {
	font-size: 12px;
}

.medium {
	font-size: 16px;
}

.verticalContainer {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	flex-grow: 1;
	overflow: auto;
}

.verticalContainer .scrollContent {
	flex-grow: 1;
	overflow: auto;
	padding-right: 15px;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.basic-button {
	background-color: black;
	color: white;
	border: 0;
	border-radius: 5px;
	min-height: 35px;
	min-width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.basic-button svg {
	font-size: 18px;
}

.menu-categories-2 {
	overflow: auto;
	border-bottom: 1px solid black;
	flex-shrink: 0;
	flex-grow: 0;
}

.menu-categories-2 .scroll-content {
	padding: 5px;
	display: flex;
	gap: 5px;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-padding: 5px;
}
.menu-categories-2 .scroll-content .category-tags > div {
	color: var(--c2c);
	background-color: var(--c2);
	font-size: 12px;
	padding: 2px 5px;
	border-radius: 3px;
	width: fit-content;
	font-weight: bold;
	margin-left: 5px;
}

.menu-categories-2 button {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	border-radius: 5px;
	border: 1px solid var(--c1);
	color: var(--c1);
	background-color: var(--c1c);
	height: 40px;
	scroll-snap-align: start;
}

.menu-categories-2 button.active {
	border: 1px solid var(--c1);
	color: var(--c1c);
	background-color: var(--c1);
}

/* width */
.menu-categories-2 .scroll-content::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

/* Track */
.menu-categories-2 .scroll-content::-webkit-scrollbar-track {
	background: #eee;
}

/* Handle */
.menu-categories-2 .scroll-content::-webkit-scrollbar-thumb {
	background: #ccc;
}

/* Handle on hover */
.menu-categories-2 .scroll-content::-webkit-scrollbar-thumb:hover {
	background: rgb(143, 143, 143);
}

.promo-popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	height: 100dvh;
	background-color: #00000090;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	z-index: 60;
}

.promo-popup .content {
	background-color: #fff;
	padding: 15px;
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	max-height: 100%;
	overflow: auto;
}

.promo-popup .content button {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 35px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: var(--c2);
	color: var(--c2c);
}

.promo-popup .content .img {
	display: flex;
	align-items: center;
	justify-content: center;
}

.promo-popup .content .img img {
	max-width: 100%;
}

.promo-popup .content .title {
	font-weight: 600;
	text-align: center;
}

.flow-popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #00000090;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	z-index: 160;
}

.flow-popup .content {
	background-color: #fff;
	padding: 15px;
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.flow-popup .content h1 {
	font-weight: 600;
	text-align: center;
	margin: 0;
	font-size: 30px;
}

.flow-popup .content button {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 35px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: var(--c2);
	color: var(--c2c);
}

textarea {
	width: 100%;
	min-height: 150px;
	border: 1px solid #eee;
	resize: vertical;
}

.table {
	display: grid;
	font-size: 14px;
}

.table > :not(:first-child) {
	border-top: 0;
}

.table > div {
	border-bottom: 1px solid #eee;
	padding: 10px;
}

.table.table-2 {
	grid-template-columns: auto auto;
}

.table.table-3 {
	grid-template-columns: auto auto auto;
}

.table.table-4 {
	grid-template-columns: auto auto auto auto;
}

.table .table-head {
	background-color: #000;
	color: #fff;
	font-weight: 600;
	border-bottom: 0;
}

.error {
	color: red;
}

.success {
	color: green;
}

.status-tab {
	display: inline-block;
	border-radius: 5px;
	padding: 2px 5px;
	font-size: 12px;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.lock-content {
	position: relative;
	flex-grow: 1;
	overflow: hidden;
}

.internal-lock {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #ffffff99;
	display: flex;
	align-items: center;
	justify-content: center;
}

.internal-lock-content {
	background-color: #ffffff;
	padding: 30px;
	width: 100%;
	max-width: 300px;
	text-align: center;
	border-radius: 15px;
	border: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.internal-lock-content .title {
	font-weight: bold;
}

.internal-lock-content button {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 35px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: var(--c2);
	color: var(--c2c);
}

.src {
	position: relative;
	padding: 10px;
}
.src input {
	display: block;
	background-color: unset;
	border-right: 0px;
	border-left: 0px;
	border-top: 0px;
	border-bottom: 2px solid #ccc;
	border-radius: 0px;
	font-weight: 100;
	padding-left: 25px;
	width: 100%;
}

.src input:focus-visible {
	outline: 0px;
	border-bottom: 2px solid var(--c1);
}
.src span {
	position: absolute;
	top: 10px;
	left: 10px;
	color: #ccc;
}

.src button {
	position: absolute;
	top: 10px;
	right: 8px;
	padding: 0;
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	border: 0px solid #ccc;
	background-color: unset;
	color: var(--c1);
	border-radius: 100px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.email-popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #00000090;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	z-index: 160;
}

.email-popup .content {
	background-color: #fff;
	padding: 15px;
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	text-align: center;
}

.email-popup .content h1 {
	font-weight: 600;
	text-align: center;
	margin: 0;
	font-size: 30px;
}

.email-popup .content form {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.email-popup .content button {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 35px;
	font-size: 16px;
	text-transform: uppercase;
	background-color: #ccc;
	color: #000;
}

.email-popup .content button.primary {
	background-color: var(--c2);
	color: var(--c2c);
}

.kiosk-payment-popup {
	position: fixed;
	width: 100%;
	height: 100%;
	height: 100dvh;
	top: 0;
	left: 0;
	background-color: rgb(0 0 0 / 85%);
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 18px;
	line-height: 22px;
	display: flex;
}
.kiosk-payment-popup button {
	background-color: var(--c2);
	color: var(--c2c);
	border: 0;
	margin: 0;
	padding: 10px 20px;
	font-weight: bold;
	border-radius: 10px;
	margin-top: 10px;
}

/* KIOSK */

#root.kiosk .close-button {
	width: 80px;
	height: 80px;
	font-size: 80px;
}
#root.kiosk .page .sidebar {
	max-width: 450px;
	min-width: 350px;
}
#root.kiosk .page .sidebar button {
	font-size: 30px;
	line-height: 38px;
	padding: 20px 30px;
}
#root.kiosk .page .sidebar .category-tags div {
	font-size: 25px;
	padding: 0 10px;
	border-radius: 5px;
}
#root.kiosk .page .sidebar button.selected::before {
	width: 12px;
}
#root.kiosk .src {
	font-size: 40px;
}
#root.kiosk .src input {
	padding-left: 55px;
}
#root.kiosk .page .main .inner-content {
	padding: 20px;
}
#root.kiosk .popup-content p.text-center {
	font-size: 30px;
	line-height: 38px;
}
#root.kiosk .popup .popup-content h4 {
	font-size: 30px;
	font-weight: 600;
	color: var(--c2);
	margin-top: 30px;
}
#root.kiosk .table {
	font-size: 30px;
	line-height: 38px;
	margin-bottom: 50px;
}
#root.kiosk .menu-categories-2 button {
	font-size: 40px;
	height: unset;
	padding: 15px 25px;
	border-radius: 10px;
}
#root.kiosk .menu-categories-2 button.active {
	font-weight: 600;
}
#root.kiosk .menu-categories-2 .scroll-content .category-tags > div {
	font-size: 20px;
}
#root.kiosk .menu-categories-2 .scroll-content {
	gap: 15px;
	padding: 20px;
}
#root.kiosk .popup .popup-header h3 {
	font-size: 40px;
	font-weight: 600;
}
#root.kiosk .popup .popup-header {
	font-size: 28px;
}
#root.kiosk .popup .finalPrice {
	font-size: 40px;
}
#root.kiosk .kiosk-payment-popup .info-text {
	font-size: 48px;
	line-height: 60px;
	margin-top: 40px;
	font-weight: 600;
}
#root.kiosk .kiosk-payment-popup i {
	font-size: 80px;
}
#root.kiosk .kiosk-payment-popup button {
	font-size: 35px;
	padding: 30px 30px;
	margin-top: 40px;
}
#root.kiosk .kiosk-payment-popup .fa-check {
	color: green;
	background-color: #fff;
	border-radius: 100%;
	padding: 30px;
}
#root.kiosk .confirm div {
	font-size: 50px;
	padding: 30px;
}

.select-languages {
	position: relative;
}

.select-languages button {
	display: flex;
	gap: 5px;
	align-items: center;
	background-color: transparent;
	padding: 0px 10px;
	border: 1px solid #eee;
	font-size: 16px;
	border-radius: 5px;
	height: 39px;
}

.select-languages button img {
	height: 20px;
}

.select-languages .submenu {
	position: absolute;
	right: 0;
	top: 45px;
	background-color: #fff;
	padding: 10px;
	border-radius: 5px;
	z-index: 1;
	border: 1px solid #eee;
	display: flex;
	flex-direction: column;
	gap: 5px;
	color: #000;
}

.expire {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000095;
	display: flex;
	align-items: center;
	justify-content: center;
}

.expire .content {
	background-color: #fff;
	padding: 30px;
	border-radius: 5px;
	border: 10px solid var(--c2);
	max-width: 350px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.qr-button {
	align-items: center;
	background-color: initial;
	border: 1px solid #eee;
	border-radius: 5px;
	display: flex;
	font-size: 16px;
	gap: 5px;
	height: 38px;
	width: fit-content;
	padding: 5px;
}

.qr-button svg {
	width: 25px;
}

.qr-popup-close {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: 1px solid var(--c1c);
	font-size: 16px;
	border-radius: 5px;
	height: 39px;
	font-size: 30px;
	line-height: 0;
	padding-top: 3px;
	color: var(--c1c);
	width: fit-content;
	padding: 10px;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #00000095;
	width: 100%;
	height: 100dvh;
}

.qr-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.qr-popup .qr-popup-container {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	pointer-events: auto;
	background-color: #fff;
	border: 3px solid var(--c6);
	background-color: #fff;
	width: 100%;
	max-width: 300px;
	max-height: 500px;
}

.qr-popup header {
	background-color: var(--c1);
	color: var(--c1c);
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	flex-shrink: 0;
	border-bottom: 1px solid #fff;
}

.qr-popup .content {
	flex-grow: 1;
	overflow: auto;
	padding: 5px;
}

.qr-popup footer {
	background-color: var(--c5);
	color: var(--c5c);
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	flex-shrink: 0;
	border-bottom: 1px solid #fff;
}

.qr-popup .buttons-group {
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 700;
	font-size: 14px;
}

.qr-code-container {
	text-align: center;
}

.qr-code-container img {
	width: 100%;
}

.qr-code-container .pin {
	font-weight: bold;
	margin-top: 20px;
}

.qr-code-container .pin > div:nth-child(2) {
	font-size: 50px;
}

.extrasmall {
	font-size: 12px;
}
