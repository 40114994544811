.cart {
	display: flex;
	flex-direction: column;
	color: #000;
	border: 0;
	background-color: #eee !important;
}

.cart a {
	color: #000;
}

.cart-header {
	background-color: var(--c1);
	color: var(--c1c);
	padding-top: 30px;
	padding-bottom: 35px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	position: relative;
}

.cart-header .close-button {
	color: var(--c1c);
}

.cart-header .header-buttons {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-top: 5px;
}

.cart-header .header-buttons button {
	border: 0;
	padding: 2px 5px;
	background-color: var(--c2);
	color: var(--c2c);
	border-radius: 5px;
	font-size: 14px;
}

.cart-scroll {
	flex-grow: 1;
	overflow: auto;
	/*transform: translate(0, -30px);*/
	margin-left: 15px;
	margin-right: 15px;
	margin-top: -30px;
	z-index: 1;
}

.cart-products {
	background-color: #fff;
	margin-bottom: 15px;
	padding: 15px;
}

.cart-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: 1px solid #ccc;
	padding: 5px 0;
	gap: 5px;
}

.cart-row .notes-button {
	background-color: var(--c1);
	color: var(--c1c);
	border: 0;
	border-radius: 5px;
	width: 30px;
	height: 30px;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cart-row .notes-button > svg {
	font-size: 20px;
}

.cart-row .qty {
	display: flex;
	align-items: center;
	gap: 5px;
}

.cart-row .name {
	font-weight: 700;
}
.cart-row .name svg {
	margin-right: 5px;
}
.cart-row .small {
	font-weight: 300;
}

.cart-row .prices {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.cart-row .prices .price {
	font-weight: bold;
	white-space: nowrap;
}

.cart .total-row {
	text-align: right;
	color: var(--c2);
	padding: 5px 0;
}

.cart .total-row span {
	font-size: 20px;
	font-weight: bold;
}

.cart .buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: space-between;
}

.cart .buttons button {
	border: 0;
	padding: 10px;
	font-weight: bold;
	font-size: 16px;
	background-color: #fff;
	color: #000;
}

.cart .buttons button.button-primary {
	background-color: var(--c2);
	color: var(--c2c);
}

.cart .delete-button {
	border: 0;
	font-weight: bold;
	background-color: #bf0725;
	color: white;
	width: 30px;
	height: 30px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 18px;
}

.cart .disclaimers {
	font-size: 9px;
	padding: 10px 15px;
}

.nexi-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100dvh;
	z-index: 200;
	background-color: #00000090;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nexi-popup iframe {
	background-color: #fff;
	width: 100%;
	height: 100%;
	max-width: 400px;
}

.points-box {
	margin-bottom: 15px;
	font-size: 12px;
}

.cart-flow-line {
	background-color: #333;
	color: white;
	padding: 5px;
	margin-left: -5px;
	margin-right: -5px;
}

.cart .promo-box {
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: #fff;
	margin-bottom: 15px;
	padding: 10px 15px;
	align-items: center;
	font-size: 12px;
}

.cart .promo-box > div {
	text-align: right;
}

.cart .title {
	font-weight: bold;
	font-size: 14px;
}

.cart .promo-box input,
.cart .promo-box select {
	font-size: 14px;
	background-color: #eee;
	color: #000;
	border: 0;
	margin: 0;
	padding: 5px 10px;
	border-radius: 10px;
}

.cart .promo-box button {
	font-size: 14px;
	background-color: var(--c2);
	color: var(--c2c);
	border: 0;
	margin: 0;
	padding: 5px 10px;
	border-radius: 10px;
}

.cart .button-groups {
	display: flex;
}

.cart .button-groups input {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.cart .button-groups button {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

/* KIOSK */
#root.kiosk .cart-header {
	padding-bottom: 45px;
	padding: 40px 25px 45px 25px;
}
#root.kiosk .cart-header h3 {
	font-size: 50px;
	font-weight: 600;
}
#root.kiosk .cart-header .small {
	font-size: 30px;
	line-height: 38px;
}
#root.kiosk .cart-header .header-buttons {
	margin-top: 20px;
	gap: 20px;
}
#root.kiosk .cart-header .header-buttons button {
	font-size: 30px;
	padding: 5px 20px;
	font-weight: 600;
}
#root.kiosk .cart-products {
	padding: 25px;
}
#root.kiosk .cart-row {
	padding: 20px 0;
}
#root.kiosk .cart-row .qty {
	gap: 40px;
}
#root.kiosk .cart .qty-selector {
	gap: 20px;
	font-size: 30px;
}
#root.kiosk .cart .qty-selector button i {
	font-size: 30px;
}

#root.kiosk .cart-row .name {
	font-size: 30px;
	line-height: 48px;
}
#root.kiosk .cart-row .name .small {
	font-size: 30px;
	line-height: 38px;
}
#root.kiosk .cart-row .prices {
	font-size: 22px;
	gap: 15px;
}
#root.kiosk .cart-row .notes-button {
	height: auto;
	width: auto;
	padding: 5px 3px;
}
#root.kiosk .cart-row .notes-button svg {
	font-size: 50px;
}
#root.kiosk .cart-row .prices .price {
	font-size: 30px;
}
#root.kiosk .cart .promo-box {
	padding: 30px 20px;
}
#root.kiosk .cart .promo-box .title {
	font-size: 30px;
	line-height: 38px;
}
#root.kiosk .cart .promo-box input,
#root.kiosk .cart .promo-box select {
	font-size: 30px;
}
#root.kiosk .cart .promo-box button {
	font-size: 30px;
	padding: 10px 20px;
}
#root.kiosk .cart .promo-box .small {
	font-size: 24px;
}
#root.kiosk .cart .delete-button {
	width: 50px;
	height: 50px;
	font-size: 50px;
}
#root.kiosk .cart .total-row {
	font-size: 40px;
	font-weight: 600;
}
#root.kiosk .cart .total-row span {
	font-size: 40px;
	font-weight: 600;
}
#root.kiosk .cart .disclaimers {
	font-size: 24px;
}
#root.kiosk .cart .form-check {
	margin-bottom: 20px;
}
#root.kiosk .cart .disclaimers .small {
	font-size: 24px;
	line-height: 50px;
}
#root.kiosk .cart .form-check-input {
	width: 40px;
	height: 40px;
	margin-right: 20px;
}
#root.kiosk .cart .buttons button {
	font-size: 40px;
}
