.category-title-container .category-tags {
	margin: 5px 10px 15px 0;
}
@media (min-width: 768px) {
	.category-title-container {
		display: flex;
		align-items: center;
	}
	.category-title-container .category-tags {
		display: flex;
		justify-content: center;
		margin: 0px 0px 0px 10px;
	}
}

.category-title-container .category-tags > div {
	color: var(--c1);
	background-color: var(--c1o);
	font-size: 12px;
	padding: 2px 5px;
	border-radius: 3px;
	width: fit-content;
	font-weight: bold;
}
.category-title {
	font-weight: bold;
	line-height: 26px;
	font-size: 22px;
	margin-bottom: 0;
}

.category-description {
	font-size: 12px;
	margin-bottom: 0;
}

.products .description {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.products .img-placeholder {
	background-color: #eee;
	width: 100%;
	height: 100%;
	border-radius: 5px;
}

.products .buttons {
	display: flex;
	gap: 5px;
	flex-shrink: 0;
}

.products .button {
	border: 0;
	background-color: transparent;

	display: flex;
	align-items: center;
	justify-content: center;
}

.products .button div {
	padding: 0px 8px;
	border-radius: 100%;
	font-size: 22px;
	color: var(--c1c);
}

.badge-container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	gap: 2px;
	white-space: nowrap;
	flex-wrap: wrap;
}

.products .badge-container > * {
	background-color: red;
	color: white;
	padding: 2px 5px;
	border-radius: 5px;
	font-size: var(--extrasmall);
	width: fit-content;
}

.products .groups {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin-bottom: 5px;
}

.products .groups > div {
	background-color: #333;
	color: #fff;
	font-size: var(--extrasmall);
	border-radius: 3px;
	padding: 2px 5px;
}

/* product-vertical */

.product-vertical {
	text-align: center;
	margin-bottom: 15px;
	position: relative;
}

.product-vertical .img {
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-vertical .img img {
	max-width: 100%;
	max-height: 100%;
}

/* product-horizontal */

@media (width >= 900px) {
	.list-horizontal {
		display: grid;
		gap: 15px;
		grid-template-columns: 1fr 1fr;
	}
}

@media (width >= 1400px) {
	.list-horizontal {
		display: grid;
		gap: 15px;
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.product-horizontal {
	display: flex;
	gap: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	cursor: pointer;
	position: relative;
}

.product-horizontal .info {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.product-horizontal .info .title {
	font-size: 14px;
}

.product-horizontal .price-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-horizontal .price-line .price {
	flex-grow: 1;
}

.product-horizontal .img {
	max-height: 150px;
	width: 30%;
	min-width: 50px;
	max-width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
}

.product-horizontal .img img {
	max-width: 100%;
	max-height: 100%;
}

.add_button_container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.tags {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.tags > div {
	color: var(--c1);
	background-color: var(--c1o);
	font-size: var(--extrasmall);
	padding: 2px 5px;
	border-radius: 3px;
}

/* product-vertical KIOSK */

#root.kiosk .kiosk-products {
	gap: 30px 20px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding-top: 20px;
}
#root.kiosk .kiosk-products .col-6 {
	width: 100%;
}
#root.kiosk .product-vertical .img {
	height: 200px;
	margin-bottom: 10px;
}
#root.kiosk .product.product-vertical .title {
	font-size: 30px;
	line-height: 38px;
	font-weight: 600;
}
#root.kiosk .product.product-vertical .small.description {
	font-size: 20px;
	line-height: 24px;
	color: #898989 !important;
}
#root.kiosk .products .description {
	-webkit-line-clamp: 2;
}
#root.kiosk .product.product-vertical .price-product {
	font-weight: 600;
	font-size: 30px;
	margin-top: 10px;
}
#root.kiosk .kiosk-products .add_button_container button i {
	font-size: 24px;
	padding: 12px;
	gap: 20px;
}
#root.kiosk .kiosk-products .qty {
	font-size: 28px;
}
#root.kiosk .kiosk-products .qty-selector {
	gap: 30px;
}

#root.kiosk .category-title-container h3 {
	font-size: 40px;
	line-height: 56px;
}
#root.kiosk .category-title-container .category-tags div {
	font-size: 25px;
	line-height: inherit;
	padding: 2px 10px;
}
#root.kiosk .category-description {
	font-size: 25px;
	line-height: inherit;
}
#root.kiosk .products .badge-container > * {
	font-size: 25px;
	padding: 0 10px;
}
#root.kiosk .tags > div {
	font-size: 25px;
	padding: 0 10px;
	margin: 10px 0;
}
