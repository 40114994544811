.mono-order-confirm {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	padding: 15px;
	width: 100%;
	height: 100%;
	height: 100dvh;
	z-index: 80;
}

.mono-order-confirm .content {
	text-align: center;
	background-color: #fff;
	padding: 30px 20px;
	max-width: 500px;
}

.mono-order-confirm img {
	width: 100%;
	max-width: 300px;
}

.mono-order-confirm .button {
	border: 0;
	display: block;
	flex-grow: 1;
	padding: 10px;
	font-weight: bold;
	margin: 0 auto;
	text-decoration: none;
}
.mono-order-confirm .content h4:nth-child(1) {
	padding-bottom: 15px;
	font-weight: 800;
}
