.courses .course {
	display: block;
	padding: 10px;
	margin-bottom: 10px;
	text-decoration: none;
	text-align: center;
	font-weight: bold;
	width: 100%;
	border: 0;
}
