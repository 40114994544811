.subfooter {
	background-color: #000;
	color: #fff;
	text-align: center;
	padding: 5px;
	font-size: 12px;
	font-weight: bold;
}

.footer-container .buttons {
	display: flex;
	gap: 5px;
	border-top: 2px solid #eee;
}

.footer-container .buttons button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	position: relative;
	width: 100%;
	border: 0;
	margin: 0;
	padding: 0;
	padding-top: 15px;
	padding-bottom: 10px;
	background-color: #fff;
	color: #000;
}

.footer-container .buttons button .icon {
	position: relative;
	font-size: 20px;
}

.footer-container .buttons button .icon i {
	display: block;
}

.footer-container .buttons button .counter {
	position: absolute;
	top: 0px;
	right: -15px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
	font-size: 12px;
	font-weight: bold;
	color: #000;
}

.footer-cart {
	background-color: var(--c1);
	color: var(--c1c);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
}

.footer-cart span {
	font-weight: bold;
	font-size: 22px;
}

.footer-cart .info {
	display: flex;
	align-items: center;
	gap: 15px;
}

.footer-cart .icon {
	position: relative;
	font-size: 22px;
}

.footer-cart .counter {
	position: absolute;
	top: -5px;
	right: -10px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
	font-size: 10px;
	font-weight: bold;
	background-color: var(--c1c);
	color: var(--c1);
	border: 1px solid var(--c1);
}

.footer-cart .cart-button {
	background-color: var(--c2);
	color: var(--c2c);
	border: 0;
	margin: 0;
	padding: 10px;
	font-weight: bold;
	border-radius: 10px;
}

.footer-cart .promo-button {
	background-color: var(--c2c);
	color: var(--c2);
	border: 0;
	margin: 0;
	padding: 10px 15px;
	font-weight: bold;
	border-radius: 10px;
	font-size: 11px;
	text-transform: uppercase;
}

.footer-cart .promo-button svg {
	margin-right: 5px;
}

/* KIOSK */
#root.kiosk .footer-container .buttons {
	display: none;
}
#root.kiosk .footer-container .buttons button {
	font-size: 30px;
	padding: 30px;
}
#root.kiosk .footer-container .buttons button .icon {
	font-size: 50px;
}
#root.kiosk .footer-container .buttons button .counter {
	font-size: 28px;
	line-height: 38px;
	padding: 1px 15px;
	border-radius: 100%;
	top: -15px;
}
#root.kiosk .subfooter {
	font-size: 30px;
}
#root.kiosk .footer-cart {
	padding: 30px 30px;
}
#root.kiosk .footer-cart .cart-button {
	font-size: 40px;
	padding: 10px 20px;
}
#root.kiosk .footer-cart .icon {
	font-size: 40px;
}
#root.kiosk .footer-cart span {
	font-size: 40px;
	margin-left: 30px;
}
#root.kiosk .footer-cart .counter {
	font-size: 26px;
	padding: 0px 15px;
	right: -25px;
}
#root.kiosk .footer-cart .promo-button {
	font-size: 30px;
	padding: 15px 25px;
}
