:root {
  --header-height: 0;
}

#menu {
  height: calc(100% - var(--header-height));
  top: var(--header-height);
  left: 0;
  overflow: auto;
  background-color: #eee;
  padding: 15px;
  position: fixed;
  width: 250px;
  z-index: 50;
}

#menu .button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  text-decoration: none;
  border: 0;
  text-align: left;
}

#menu .version {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}

#menu .version div {
  text-align: center;
}

#menu .version a {
  text-decoration: none;
  color: #000;
}

#menu .course-title {
  padding: 10px;
  font-weight: bold;
}

#menu .category-tags > div {
  color: var(--c2c);
  background-color: var(--c2);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  width: fit-content;
  font-weight: bold;
}

/* KIOSK */
#root.kiosk #menu {
  max-width: 450px;
  min-width: 350px;
  padding: 20px;
}
#root.kiosk #menu .button {
  font-size: 35px;
}
#root.kiosk #menu .lang-selector .label {
  font-size: 30px;
}
#root.kiosk #menu .lang-selector .lang-buttons {
  gap: 20px;
}
#root.kiosk #menu .lang-selector .lang-buttons a {
  width: 80px;
}
#root.kiosk #menu .lang-selector .lang-buttons a img {
  width: 80px;
}
#root.kiosk #menu .version {
  font-size: 22px;
}
