#lock {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100dvh;
	background-color: #00000090;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 300;
}

#lock .content {
	text-align: center;
}

#lock .button {
	border: 0;
	display: block;
	padding: 10px;
	font-weight: bold;
	margin-top: 10px;
	text-decoration: none;
}
